.container {
    display: grid;
    gap: 20px;
}

.titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
}

.hintIcon {
    margin-left: 8px;
}

.paragraphCommon {
    color: var(--vkui--color_text_subhead);
}

.paragraphDesktop {
    text-align: center;
}

.paragraphMobile {
    text-align: start;
}

.regenerateButton {
    margin: 14px 10px;
}

.image {
    width: 100%;
    border-radius: 8px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
