.headerContainer {
    padding: 0 10px;
}

.title {
    text-align: center;
}

.titleContainer {
    margin-bottom: 2px;
    margin-top: 13px;
}
