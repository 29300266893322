.container {
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5px;
    flex-wrap: wrap;
}

.buttonsContainer {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}
