.rounded {
    border-radius: 6px;
}

.text {
    color: white;
}

.score {
    font-weight: 700;
    line-height: 0.9em;
}

.score.primary {
    margin: 0 6px 0 0;
}

.score.emphasized {
    margin: 0 0 6px 0;
}

.container {
    padding: 8px 12px;
    display: flex;
}

.container.primary {
    flex-direction: row;
    align-items: center;
}

.container.emphasized {
    flex-direction: column;
    align-items: flex-start;
}

