.containerCommon {
    margin-bottom: 12px;
}

.twoColumnsContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.oneColumnContainer {
    display: flex;
    flex-direction: column;
}

.itemContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.itemShorted {
    width: calc(50% - 10px);
}

.icon {
    margin-right: 8px;
    min-width: 16px;
}

.textContainer {
    margin-bottom: 10px;
}

.text {
    color: var(--vkui--color_text_subhead);
}

.text::first-letter {
    text-transform: capitalize;
}
