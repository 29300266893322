.container_dark {
    padding: 10px;
    background-color: rgb(31, 31, 31);
}

.container_light {
    padding: 10px;
    background-color: rgb(250, 250, 250);
}

.button {
    width: 100% !important;
}

.buttonContainer {
    margin: 15px;
}
