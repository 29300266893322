.titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.container_open {
    display: flex;
    min-height: 60vh;
}

.panelContainer {
    width: 96%;
    padding: 2%;
}

.backgroundEven_dark {
    background-color: rgb(31, 31, 31);
    border-radius: 20px;
}

.backgroundOdd_dark {
    background-color: rgb(42, 42, 42);
    border-radius: 20px;
}

.backgroundEven_light {
    background-color: rgb(234, 234, 234);
    border-radius: 20px;
}

.backgroundOdd_light {
    background-color: rgb(250, 250, 250);
    border-radius: 20px;
}
