.passingTime {
  color: '#818c99';
}

.testName {
  font-family: var(--vkui--font_title2--font_family--regular);
  font-size: var(--vkui--font_title2--font_size--regular);
  font-weight: var(--vkui--font_title2--font_weight--regular);
  line-height: var(--vkui--font_title2--line_height--regular);
}
