.loading:after {
  display: inline-block;
  animation: ellipsis 1.2s infinite;
  content: '';
  width: 1em;
  text-align: left;
}

@keyframes ellipsis {
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}
