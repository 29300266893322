.container_light, .container_dark {
    padding: 15px;
    margin: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    transition: color .3s,
                background-color .3s, 
}

.container_dark {
    background-color: rgb(21, 21, 21);
    border: 2px solid white;
    color: white;
}

.container_light {
    background-color: white;
    border: 2px solid rgb(21, 21, 21);
    color: rgb(21, 21, 21);
}

.container_dark:hover{
    background-color: white;
    color: rgb(21, 21, 21)
}

.container_light:hover {
    background-color: rgb(21, 21, 21);
    color: white;
}

.textContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.hidden {
    margin-left: auto;
    margin-right: auto;
}
