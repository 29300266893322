.codeContainer_dark, .codeContainer_light{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    color: white;
    font-size: 24px;
    margin-top: 5px;
    flex-wrap: wrap;
}

.codeContainer_dark {
    background-color: #3d3d3d;
    color: white;
}

.codeContainer_light {
    background-color: #ffffff;
    color: #3d3d3d;
}

.buttonContainer {
     margin: 15px;
}

.button {
    width: 100% !important;
}

.container_dark {
    background-color: rgb(42, 42, 42);
    padding: 10px;
}

.container_light {
    background-color: rgb(234, 234, 234); 
    padding: 10px;
}

.code {
    margin: 10px;
}