.container_light {
    background-color: rgb(234, 234, 234);
    padding: 10px;
}

.container_dark {
    background-color: rgb(42, 42, 42);
    padding: 10px;
}

.button {
    width: 100% !important;
}

.buttonContainer {
    margin: 15px;
}
