.container {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.friendsContainer {
    padding: 15px;
    margin-bottom: 150px !important;
}
