.container {
    padding: 10px;
}

.desktopContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.textContentContainer {
    margin-right: 6px;
}

.image {
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 1;
}

.title {
    text-align: center;
}

.paragraph {
    color: var(--vkui--color_text_subhead);
}
