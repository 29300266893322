.banner {
    height: 250px;
    border-radius: 8px;
    
    background-color: #5D6670;
    background-image: url('./iconDesktop.svg');
    background-repeat: no-repeat;
    background-position: right;

    padding: 30px 40px;
    
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.content {
    width: 308px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    color: var(--vkui--color_text_contrast);
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 310px;
}

.actions > :global(.vkuiButton--mode-primary.vkuiButton--appearance-overlay),
.actions > :global(.vkuiButton--mode-primary.vkuiButton--appearance-overlay.vkuiTappable--hover-has) {
    color: #2688EB;
}

@media (max-width: 552px) {
    .banner {
        height: 660px;

        background-image: url('./iconMobile.svg');
        background-position: bottom;
        background-size: cover;

        align-items: center;
    }

    .content {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .actions {
        width: 100%;
    }
}