.container_open {
    display: flex;
    gap: 20px;
    padding: 2%;
    width: 100%;
    min-height: 60vh;
    padding-bottom: 50px;
}

.panelContainer {
    width: 96%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 15px;
}

.img {
    width: 90%;
    border-radius: 15px;
}

.titleContainer {
    display: flex;
    justify-content: center;
    margin: 15px;
}

.backgroundEven_dark {
    background-color: rgb(31, 31, 31);
    border-radius: 20px;
}

.backgroundOdd_dark {
    background-color: rgb(42, 42, 42);
    border-radius: 20px;
}

.backgroundEven_light {
    background-color: rgb(234, 234, 234);
    border-radius: 20px;
}

.backgroundOdd_light {
    background-color: rgb(250, 250, 250);
    border-radius: 20px;
}

.userResult {
    padding: 15px;
    padding-top: 25px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.friendsResultsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 50px;
    width: 90%;
}
