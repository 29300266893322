.container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 200px;
    margin-top: 10px;
}

.button {
    width: 100% !important;
}

.menuContainer{
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px;
    margin-right: 15px;
}

.open_dark, .open_light {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.open_dark {
    background-color: rgb(46, 46, 46);
    box-shadow: 5px 5px 5px black;
}

.open_light {
    background-color: rgb(234, 234, 234);
    box-shadow: 5px 5px 5px rgb(97, 97, 97);
}
