.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2%;
    margin-bottom: 5px;
    flex-wrap: wrap;
    width: 100%;
    flex-wrap: wrap;
}


