.container {
    max-width: 134px !important;
    padding: 2px 9px !important;
}

.containerDark::before {
    border: none !important;
}

.contentContainer {
    display: flex;
    text-decoration: none;
    font-family: inherit;
    flex-direction: column;
}

.avatarContainer {
    display: flex;
    justify-content: center;
    padding: 4px 8px;
}

.textContainer {
    padding: 2px 4px 8px;
}

.text {
    color: var(--vkui--color_text_primary);
    text-overflow: ellipsis;
    word-wrap: normal;
    text-align: center;
}
