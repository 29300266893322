.panelContainer {
    width: 92%;
    padding: 4%;
}

.titleContainer {
    display: flex;
    justify-content: center;
    margin: 15px;

}

.text {
    text-align: left;
    padding-left: 5%;
}

.textBlock {
    margin-top: 50px;
    margin-bottom: 50px;
}

.description {
    text-align: left;
}

.flexContainer {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 25px;
}

.resultContainer_desktop, .resultContainer_mobile {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 25px;
}

.resultContainer_mobile {
    flex-direction: column;
}