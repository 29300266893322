.container_dark, .container_light {
    border-radius: 15px;
    display: flex;
    gap: 5px;
    padding: 3%;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 94%;
}

.container_dark:hover {
    background-color: rgb(26, 26, 26);
}

.container_light:hover {
    background-color: rgb(220, 220, 220);
}

.flexContainer {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
}

.textCotnainer {
    display: flex;
    flex-direction: column;
}

.subtitle {
    font-size: 15px;
}
