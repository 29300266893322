.container {
    width: 100%;
    min-height: 270px;
}

.autoGap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.customGap {
    display: grid
}
