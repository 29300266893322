.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.userContainer {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

h3 {
    text-align: center;
}

.text {
    font-size: 21px;
}

p {
    text-align: center;
}
