.container_open {
    display: flex;
}

.testsDesktopContainer {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 96%;
    padding: 2%;
}

.testsMobileContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.menuIcon {
    margin-bottom: 15px;
    margin-left: 15px;
}
