.container {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5px;
    flex-wrap: wrap;
}


