.container_dark, .container_light {
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    background-color: rgb(46, 46, 46);
    justify-content: start;
    align-items: center;
    height: 74vh;
}

.container_dark {
    background-color: rgb(46, 46, 46);
}

.container_light {
    background-color: rgb(234, 234, 234);
}

.button {
    height: 8vh !important;
    width: 100% !important;
}
