.container_dark, .container_light {
    box-shadow: 5px 5px 15px black;
    border-radius: 15px;
    transition: box-shadow .2s
                color .2s;
}

.container_dark_mobile, .container_light_mobile {
    width: 90%;
    margin-right: 15px;
} 

.container_dark_desktop, .container_light_desktop {
    width: 48%;

}

.container_dark {
    background-color: rgb(47, 47, 47);
}

.container_light {
    background-color: rgb(239, 239, 239);
}

.container_dark_desktop:hover .imageHover, .container_light_desktop:hover .imageContainer {
    width: 250px;
}

.container_dark_mobile:hover .imageHover, .container_light_mobile:hover .imageContainer {
    width: 48%;
}

.container_light:hover .imageHover {
    background-color: rgba(238, 238, 238, 0.05);
}

.container_dark:hover .imageHover {
    background-color: rgb(47, 47, 47, .3);
}

.container_dark:hover {
    color: white;
}

.container_light:hover {
    color: black;
}

.container_dark:hover, .container_light:hover{
    box-shadow: 2px 2px 8px black;
}

.img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 180px;
    display: block;
    object-fit: cover;
}

.titleContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.friendsInfo_desktop {
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;

}

.friendsInfo_mobile {
    margin-left: 15px;
    margin-top: 55px;
    margin-bottom: 15px;
}