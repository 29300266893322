.withImage {
    background-image: url("../../assets/imageLoadingBackground.jpg");
}

.container {
    width: 100%;
    background-size: cover;
    border-radius: 8px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.title {
    color: white;
    text-align: center;
}

.titleContainer {
    margin: 20px 6px 8px;
}

.secondaryText {
    color: white;
    text-align: center;
}

.secondaryTextContainer {
    margin: 0 6px 32px;
}

.loaderContainer {
    margin-bottom: 20px;
    height: 50%;
    width: 50%;
}
