.content {
  min-width: 240px;
  max-width: 416px;
  padding: 24px 24px 20px;
  margin: 32px;
}

.header {
  text-align: center;
}

.textContainer {
  margin: 9px 0 13px;
}

.text {
  text-align: center;
  color: var(--vkui--color_text_secondary);
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  width: 120px;
}
