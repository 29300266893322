.titleContainer {
    display: flex;
    justify-content: center;
    margin: 15px;
}

.testContainer {
    margin: 15px;
    margin-bottom: 50px;
    box-shadow: 5px 5px 5px black;
    transition: box-shadow .3s;
}

.testContainer:hover {
    box-shadow: none;
}

.container {
    padding: 15px !important;
}
