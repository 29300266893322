.card {
  --margin-top: 12px;
  --margin-bottom: 17px;
  --horizontal-edge-margin: 12px;
  --horizontal-standart-margin: 10px;

  margin: var(--margin-top) var(--horizontal-standart-margin) var(--margin-bottom);
  height: calc(100% - var(--margin-top) - var(--margin-bottom));
  /* Переопределяем vkui */
  width: calc(100% - 2 * var(--horizontal-standart-margin)) !important;
  min-height: 0 !important;
}

.first {
  margin-left: var(--horizontal-edge-margin);
  /* Переопределяем vkui */
  width: calc(100% - var(--horizontal-standart-margin) - var(--horizontal-edge-margin)) !important;
}

.last {
  margin-right: var(--horizontal-edge-margin);
  /* Переопределяем vkui */
  width: calc(100% - var(--horizontal-standart-margin) - var(--horizontal-edge-margin)) !important;
}

.first.last {
  /* Переопределяем vkui */
  width: calc(100% - 2 * var(--horizontal-edge-margin)) !important;
}
