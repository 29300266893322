.content {
    padding: 24px 24px 20px;
}

.header {
    text-align: center;
}

.textContainer {
    margin: 16px 0 24px;
}

.text {
    text-align: center;
    color: var(--vkui--color_text_secondary);
}
